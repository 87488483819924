import React from "react";

function About() {
  return (
    <section
      className=" flex md:w-[100%] md:h-[100%] md:flex-row flex-col-reverse justify-center items-center  "
      id="about"
    >
      <div className="md:w-[200%] md:h-[200%] md:mt-8 relative md:ml-0 maxsm:ml-19  max-sm:w-[100%]">
        <img
          className="md:w-[100%] max-sm:w-[110%]  md:h-[200%]  max-sm:mt-[2vh] "
          src="../images/group.jpeg"
          alt="Rectangleblack"
        />
      </div>
      <div className="md:w-[100%] w-[80%] md:h-[80%] md:mt-8  bg-black md:ml-0 py-2 px-4">
        <p className="font-caveat   text-xl font-semibold text-white  md:mt-[1rem]">
          The founder
        </p>
        <h1 className="md:text-sky-300xl text-3xl font-bold leading-[2 rem] md:font-semibold font-poppins md:leading-[1.4rem] text-white  md:mt-[2rem]">
          I’m Rushikesh koli -
          <br /> <br />
          founder of 5TechG
        </h1>
        <p className="mt-[1 rem] md:text-xl  text-white font-poppins md:mt-[0.5rem] ">
          Our dedicated team thrives on creativity and collaboration, crafting
          tailored solutions that elevate brands and exceed expectations.
        </p>
        <p className="mt-[1 rem] md:text-xl  text-white font-poppins md:mt-[[0.5srem]">
          Together, we harness the power of technology and creativity to shape
          impactful narratives and drive meaningful engagement.
        </p>
        <button
          className=" block md:inline text-center md:ml-3 ml-2.5 font-poppins rounded-lg relative  px-1 py-2  md:w-[30%] w-[38%] md:h-[40%] md:mt-[1rem] mt-[0.5rem]  text-lg border-2 border-spacing-0 border-gray-400  
            "
          style={{ backgroundColor: "#D0D4BD" }}
        >
          work with me
        </button>
      </div>
    </section>
  );
}

export default About;
