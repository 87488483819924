import React from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";

const Header = () => {
  const [showMenu, setShowMenu] = React.useState(false);
  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <header
        style={{ borderColor: "#7DA0CA" }}
        className={`flex justify-around items-center h-[10vh] w-[100%] font-medium ${
          showMenu ? "border-0" : "border-b-2"
        }  `}
      >
        {showMenu || (
          <div>
            {/* <img
              className="md:w-[70%] w-[60%]"
              src="../images/logo.png"
              alt="logo"
            /> */}
          </div>
        )}
        {showMenu && (
          <div className="fixed bottom-0 w-full h-full md:hidden">
            <RxCross2
              style={{ borderColor: "#7DA0CA", backgroundColor: "#F1EerE7" }}
              onClick={handleMenu}
              className="mt-[6%] ml-[75%] border-8 rounded-full text-6xl md:hidden"
            />
            <ul className="flex flex-col text-4xl mt-[10%] ml-[5%]  ">
              <li className="mt-[8%]">
                <a onClick={handleMenu} href="#home">
                  Home
                </a>
              </li>
              <li className="mt-[8%]">
                <a onClick={handleMenu} href="#about">
                  About
                </a>
              </li>
              <li className="mt-[8%]">
                <a onClick={handleMenu} href="#contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        )}
        <nav className="w-[50%] hidden md:block ">
          <ul className="flex justify-around ">
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#works">Works</a>
            </li>
            <li>
              <a href="#blog">Blog</a>
            </li>
            <li className="bg-black text-white  px-6 py-2 rounded-full">
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>
        {showMenu || (
          <RxHamburgerMenu
            onClick={handleMenu}
            className="text-4xl md:hidden"
          />
        )}
      </header>
    </>
  );
};

export default Header;
