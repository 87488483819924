import React from "react";

function Projects() {
  return (
    <section
      className="    md:w-[100%] md:h-[99vh]      px-6 gap-8 py-8 md:py-12 overflow-hidden"
      id="works"
    >
      <div className="">
        <h1 className="bg-cyan-500 text-white text-3xl px-4 py-2 rounded-tl-3xl mb-3 lg:w-[14%]  w-[50%] md:h-[9%] font-caveat">
          Projects⚪
        </h1>
        <div className="flex max-sm:flex-col-reverse justify-between max-sm:ml-7  ">
          <div className="bg-yellow-100 rounded-tr-3xl  lg:w-[25vw] w-[90%] lg:h-[40%] h-[90%] md:h-[60%] pt-3 flex flex-col max-sm:mt-[3vh]">
            <h1 className="text-2xl mt-40 ml-3 font-caveat ">
              Private cloud setup
            </h1>
            <img
              className="absolute lg:w-[25%] w-[75%] md:h-[23%] h-[16%] md:px-3    px-4 "
              src="../images/digitalman.png"
              alt="Rectangleblack"
            />
            <p className="px-3 font-poppins">
              Successfully organized and hosted an engaging web development
              seminar
            </p>
            <p className="md:mt-[7%] font-caveat    mt-[8%] md:ml-[80%] ml-[80%] md:py-2 ">
              view
            </p>
          </div>

          <div className="bg-yellow-100 rounded-tr-3xl  lg:w-[25vw] w-[90%] lg:h-[40%] h-[90%] md:h-[60%] pt-3 flex flex-col lg:mt-[20%] max-sm:mt-[2vh]">
            <h1 className="text-2xl mt-40 ml-3 font-poppins ">
              Weapon detection syestem
            </h1>
            <img
              className="absolute lg:w-[25%] w-[75%] md:h-[23%] h-[16%] md:px-3    px-4 "
              src="../images/wepandeteaction.png"
              alt="wepandeteaction"
            />
            <p className="px-3 font-poppins">
              Successfully organized and hosted an
            </p>
            <p className="md:mt-[7%] font-caveat mt-[10%] md:ml-[80%] ml-[80%] md:py-2 ">
              view
            </p>
          </div>

          <div className="bg-yellow-100 rounded-tr-3xl  lg:w-[25vw] w-[90%] lg:h-[30%] h-[90%] md:h-[60%] pt-3 flex flex-col max-sm:mt-[2vh]">
            <h1 className="text-2xl mt-40 ml-3 font-poppins ">
              ERP for manufacturing Industry
            </h1>
            <img
              className="absolute lg:w-[25%] w-[75%] md:h-[23%] h-[16%] md:px-3    px-4 "
              src="../images/erp.png"
              alt="erp"
            />
            <p className="px-3 font-poppins">
              Successfully organized and hosted an engaging web development
              seminar
            </p>
            <p className="md:mt-[7%] font-caveat mt-[3%] md:py-2 md:ml-[80%] ml-[80%]">
              view
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Projects;
