import React from "react";

const Blog1 = () => {
  return (
    <section className="overflow-hidden " id="blog">
      <div className="blog1 lg:h-[60vh] h-[50vh] lg:w-[100vw] flex justify-center gap-[80px] max-sm:grid max-sm:grid-rows  lg:mt-[30vh] max-sm:mt-[10vh]">
        <div>
          <div className=" lg:mt-[6vh] mt-0 text-black grid grid-rows lg:mr-[20vh] lg:ml-20 ml-10">
            <div>
              <h1 className="text-4xl pt-[0vh] font-caveat">blog post</h1>
            </div>

            <p className="lg:text-1xl text-2xl lg:w-[40vw] lg:h-[30vh]  w-[70%] lg:mt-4 ml-4">
              Successfully organized and hosted an engaging web development
              seminar, empowering attendees with the latest industry insights
              and practical skills
            </p>
          </div>
        </div>

        <div className=" md:w-[100vw] lg:h-[60vh] relative  mr-[20vh] lg:mb-4 mb-40">
          <img
            className="absolute lg:w-[100%] w-[90%] top-[4%] lg:right-0 max-sm:left-7 "
            src="../images/reactanglebackground.png"
            alt="reactanglebackground"
          />
          <div
            className="relative lg:px-1 px-2 lg:py-3 py-1 md:w-[10%] w-[10%] text-center text-xl  bg-black text-white md:left-[90%] lg:left-[90%] max-sm:left-[77%] lg:mt-5 "
            style={{ borderRadius: "50%" }}
          >
            1
          </div>
          <img
            className="absolute lg:w-[100%] w-[80%] lg:left-[10%] left-[30%] md:mt-4 mt-1  lg:mb-0 mb-2"
            src="../images/1t.png"
            alt="1t"
          />
        </div>
      </div>
      <div className="blog1 lg:h-[60vh] h-[50vh] lg:w-[100vw] flex justify-center gap-[80px] max-sm:grid max-sm:grid-rows  mt-[30vh] bg-black ">
        <div>
          <div className=" lg:mt-[6vh] text-white lg:w-[50%] w-[90%] lg:h-[60%] h-[40%] grid grid-rows lg:mr-[20vh] lg:ml-[10%] ml-[10%]">
            <div>
              <h1 className="lg:text-3xl   text-3xl lg:pt-[0vh] font-caveat">
                blog post 2
              </h1>
            </div>

            <p className="lg:text-1xl text-2xl lg:w-[40vw] lg:h-[30vh]  w-[70%] lg:mt-4 ml-4">
              Successfully organized and hosted an engaging web development
              seminar, empowering attendees with the latest industry insights
              and practical skills
            </p>
          </div>
        </div>

        <div className=" md:w-[100vw] lg:h-[60vh] relative  mr-[20vh] lg:mb-4 mb-40">
          <img
            className="absolute md:w-[86%] w-[71%] top-[4%] md:right-7 right-8 "
            src="../images/reactanglebackground.png"
            alt="Rectangleblack"
          />
          <div
            className=" relative lg:px-1 lg:py-3 lg:w-[10%] w-[10%] text-center text-xl text-black md:left-[90%] left-[90%] md:mt-3"
            style={{ borderRadius: "50%", backgroundColor: "#e2dbc2 " }}
          >
            2
          </div>
          <img
            className="absolute lg:w-[80%] w-[71%] left-[25%] md:mt-4"
            src="../images/1t.png"
            alt="Rectangle60"
          />
        </div>
      </div>
      <div className="blog1 lg:h-[60vh] h-[50vh] lg:w-[100vw] flex justify-center gap-[80px] max-sm:grid max-sm:grid-rows  mt-[30vh] mb-[20%]">
        <div>
          <div className=" lg:mt-[6vh] mt-0 text-black grid grid-rows lg:mr-[20vh] lg:ml-20 ml-10">
            <div>
              <h1 className="text-4xl pt-[0vh] font-caveat">blog post</h1>
            </div>

            <p className="lg:text-1xl text-2xl lg:w-[40vw] lg:h-[30vh]  w-[70%] lg:mt-4 ml-4">
              Successfully organized and hosted an engaging web development
              seminar, empowering attendees with the latest industry insights
              and practical skills
            </p>
          </div>
        </div>

        <div className=" md:w-[100vw] lg:h-[60vh] relative  mr-[20vh] lg:mb-4 mb-40">
          <img
            className="absolute lg:w-[100%] w-[90%] top-[4%] lg:right-0 max-sm:left-7 "
            src="../images/reactanglebackground.png"
            alt="reactanglebackground"
          />
          <div
            className="relative lg:px-1 px-2 lg:py-3 py-1 md:w-[10%] w-[10%] text-center text-xl  bg-black text-white md:left-[90%] lg:left-[90%] max-sm:left-[77%] lg:mt-5 "
            style={{ borderRadius: "50%" }}
          >
            1
          </div>
          <img
            className="absolute lg:w-[100%] w-[80%] lg:left-[10%] left-[30%] md:mt-4 mt-1  lg:mb-0 mb-2"
            src="../images/1t.png"
            alt="1t"
          />
        </div>
      </div>
    </section>
  );
};

export default Blog1;
