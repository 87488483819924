import React from "react";
import video from "../videos/1.mp4";
import video1 from "../videos/2.mp4";
const Video = () => {
  return (
    <>
      <div className=" md:mx-[30%]  md:w-fit lg:h-[90vh] max-sm:h-[60vh] max-sm:mt-6 max-sm:mb-[30vh] flex md:flex-row flex-col justify-center items-center lg:mt-[10vh] ">
        <div className="flex flex-col gap-8 md:flex-col lg:h-[60vh] max-sm:h-[40vh] md:justify-center items-center ">
          <video
            className="lg:h-[40%] max-sm:h-[60%] lg:w-[90%] max-sm:w-[80%]"
            controls
          >
            <source src={video} type="video/mp4" />
          </video>
          <p className="mt-[1 rem] md:text-xl font-semibold  md:mt-[[0.5srem] px-4">
            Our dedicated team thrives on <br></br> creativity and
            collaboration, crafting
          </p>
        </div>
        <div className="  max-sm:h-[10vh]  max-sm:px-5 ">
          <div className="flex flex-col lg:h-[60vh] md:flex-col gap-8 md:justify-center items-center ">
            <video
              className="lg:h-[50%] max-sm:h-[30% Lg:w-[10%] max-sm:w-[40%] "
              controls
            >
              <source src={video1} type="video/mp4" />
            </video>
            <p className="mt-[0.5 rem] md:text-xl font-semibold  md:mt-[[0.5srem] max-sm:mt-[[0.5srem] max-sm:px-3 px-4">
              Our dedicated team thrives on <br></br> creativity and
              collaboration, crafting
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
