import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

function MySwiper() {
  return (
    <>
      <div className="md:w-[100%] md:h-100vh">
        <Swiper
          effect={"coverflow"}
          grabCursor={false}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          pagination={{ el: ".swiper-pagination", clickable: false }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
        >
          <SwiperSlide>
            slide 1
            <div className="lg:w-[90%] max-sm:w-[100%] lg:h-[80%] max-sm:h-[90vh] md:mt-8 relative md:ml-[15%] max-sm:px-3 flex flex-col justify-between">
              <img
                className="md:w-[80%] mxsm:w-[70%]   md:h-[100%] max-sm:h-[40vh]  "
                src="../images/GROUP4.PNG"
                alt="Rectangleblack"
              />

              <div className="md:w-[100%] w-[80%] md:h-[80%] md:mt-8 max-sm:mt-2  md:ml-0 py-2 px-2 max-sm:ml-9">
                <p className="lg:mt-[1 rem] max-sm:mt-[3rem] md:text-xl  font-poppins md:mt-[0.5rem] ">
                  Our dedicated team thrives on creativity and collaboration,
                  crafting tailored solutions that elevate brands and exceed
                  expectations.
                </p>
                <p className="lg:mt-[1 rem] max-sm:mt-[2rem] md:text-xl  font-poppins md:mt-[0.5rem] ">
                  Our dedicated team thrives on creativity and collaboration,
                  crafting tailored solutions that elevate brands and exceed
                  expectations.
                </p>
                <p className="lg:mt-[1 rem] max-sm:mt-[2rem] md:text-xl  font-poppins md:mt-[0.5rem]">
                  Our dedicated team thrives on creativity and collaboration,
                  crafting tailored solutions that elevate brands and exceed
                  expectations.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            Slide 2
            <div className="lg:w-[90%] max-sm:w-[100%] lg:h-[80%] max-sm:h-[40vh] md:mt-8 relative md:ml-[15%] max-sm:px-3 flex flex-col justify-between">
              <img
                className="md:w-[80%] mxsm:w-[70%]   md:h-[100%]   "
                src="../images/Rectangle 73.PNG"
                alt="Rectangleblack"
              />

              <div className="md:w-[100%] w-[80%] md:h-[80%] md:mt-8  md:ml-0 py-2 px-4 max-sm:ml-9">
                <p className="lg:mt-[1 rem] max-sm:mt-[2rem] md:text-xl  font-poppins md:mt-[0.5rem] ">
                  Our dedicated team thrives on creativity and collaboration,
                  crafting tailored solutions that elevate brands and exceed
                  expectations.
                </p>
                <p className="lg:mt-[1 rem] max-sm:mt-[2rem] md:text-xl  font-poppins md:mt-[0.5rem] ">
                  Our dedicated team thrives on creativity and collaboration,
                  crafting tailored solutions that elevate brands and exceed
                  expectations.
                </p>
                <p className="lg:mt-[1 rem] max-sm:mt-[2rem] md:text-xl  font-poppins md:mt-[0.5rem] ">
                  Our dedicated team thrives on creativity and collaboration,
                  crafting tailored solutions that elevate brands and exceed
                  expectations.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="lg:w-[90%] max-sm:w-[100%] lg:h-[80%] max-sm:h-[40vh] md:mt-8 relative md:ml-[15%] max-sm:px-3 flex flex-col justify-between">
              <img
                className="md:w-[80%] mxsm:w-[70%]   md:h-[100%]  "
                src="../images/Rectangle 73.PNG"
                alt="Rectangleblack"
              />

              <div className="md:w-[100%] w-[80%] md:h-[80%] md:mt-8  md:ml-0 py-2 px-4 max-sm:ml-9">
                <p className="lg:mt-[1 rem] max-sm:mt-[2rem] md:text-xl  font-poppins md:mt-[0.5rem] ">
                  Our dedicated team thrives on creativity and collaboration,
                  crafting tailored solutions that elevate brands and exceed
                  expectations.
                </p>
                <p className="lg:mt-[1 rem] max-sm:mt-[2rem] md:text-xl  font-poppins md:mt-[0.5rem] ">
                  Our dedicated team thrives on creativity and collaboration,
                  crafting tailored solutions that elevate brands and exceed
                  expectations.
                </p>
                <p className="lg:mt-[1 rem] max-sm:mt-[2rem] md:text-xl  font-poppins md:mt-[0.5rem] ">
                  Our dedicated team thrives on creativity and collaboration,
                  crafting tailored solutions that elevate brands and exceed
                  expectations.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <div className="slider-controler">
            <div className="swiper-button-prev slider-arrow">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <div className="swiper-button-next slider-arrow">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
      </div>
    </>
  );
}

export default MySwiper;
