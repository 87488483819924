import React from "react";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { IoIosSend } from "react-icons/io";
const Footer = () => {
  const goTobBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <footer className="border-t-2 border-opacity-40L mt-[7%] pb-[6%]">
      <div className="container mx-auto px-6 py-4">
        <div className="md:ml-[15%] flex justify-center  px-8 ">
          <button
            className=" py-2 px-10 rounded-3xl border-2  flex gap-[10vh] items-center"
            style={{ backgroundColor: "#eeeadc" }}
          >
            Type a message
            <div className="text-3xl rotate-12 ">
              <IoIosSend />
            </div>
          </button>
        </div>

        <div className="md:flex md:flex-start   md:flex-nowrap">
          <div className="ml-0 ">
            <h1 className="text-xs sm:text-base sm:block sm:mt-0 ml-0   ">
              © 2024 5TechG
            </h1>
          </div>
          <div className="md:flex   md:flex-nowrap md:ml-[40%] gap-8 mt-[1%]">
            {" "}
            <div className="text-sm sm:text-base">
              <a
                href="#home"
                className="mt-2 block sm:inline-block sm:mt-0 sm:ml-4 hover:underline"
              >
                Home
              </a>
            </div>
            <div className="text-sm sm:text-base">
              <a
                href="#about"
                className="mt-2 block sm:inline-block sm:mt-0 sm:ml-4 hover:underline"
              >
                About
              </a>
            </div>
            <div className="text-sm sm:text-base">
              <a
                href="#works"
                className="mt-2 block sm:inline-block sm:mt-0 sm:ml-4 hover:underline"
              >
                works
              </a>
            </div>
            <div className="text-sm sm:text-base">
              <a
                href="#blog"
                className="mt-2 block sm:inline-block sm:mt-0 sm:ml-4 hover:underline"
              >
                Blog
              </a>
            </div>
          </div>
          <div className="text-3xl rounded-full lg:ml-[30%] ml-[90%] ">
            <button onClick={goTobBtn}>
              <IoIosArrowDropupCircle />
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
