import "./App.css";
import About from "./componets/About";
import Blog1 from "./componets/Blog1";
import Header from "./componets/Header";
import Herosection from "./componets/Herosection";
import MySwiper from "./componets/MySwiper";
import Projects from "./componets/Projects";
import Video from "./componets/Video";
import Footer from "./componets/footer";

function App() {
  return (
    <div className="App">
      <Header />
      <Herosection />
      <About />
      <Video />
      <Blog1 />
      <Projects />
      <MySwiper />
      <Footer />
    </div>
  );
}

export default App;
