const SectionComponent = () => {
  return (
    <section className="flex md:w-[100%] md:h-[90vh]   md:flex-col max-sm:flex-col-reverse justify-between px-6 gap-8 py-8 md:py-12 overflow-hidden">
      <div className="md:w-[40%] order-1 md:order-0 md:ml-[10%] lg:mb-[40vh] max-sm:mb-[2 0vh]">
        <button className=" border-2 border-gray-600 px-6 py-2 rounded-full font-caveat">
          Know More
        </button>
        <h2 className="px-1 text-5xl pt-3 font-caveat font-semibold">
          Rushikesh Koli
        </h2>
        <p className="mt-[1rem] md:text-xl font-poppins pt-3">
          As the CEO of our digital marketing agency, I am passionately
          committed to driving transformative growth for our clients. With a
          focus on innovation and strategy, we navigate the dynamic digital
          landscape to deliver measurable results. Our dedicated team thrives on
          creativity and collaboration, crafting tailored solutions that elevate
          brands and exceed expectations.
        </p>
      </div>
      <div className="max-sm:flex flex lg:ml-[30%]">
        <div className="lg:w-[99%] max-sm:w-[100%] max-sm:mt-[2vh] order-1 md:order-1 relative md:mt-5 max-sm:mb-[30vh]">
          <img
            className="absolute md:w-[40%] w-[51%] right-[13%]"
            src="../images/Frame 5.png"
            alt="Rectangleblack"
          />
        </div>
        <div className="md:w-[10%] w-[15%] order-1 md:order-3 flex flex-col  relative md:mt-20">
          <img
            className="absolute md:w-[40%] w-[51%] top-[20%]"
            src="../images/instagram.png"
            alt="instagram"
          />
          <img
            className="absolute md:w-[40%] w-[51%] top-[50%]"
            src="../images/linkdin.png"
            alt="linkden"
          />
          <img
            className="absolute md:w-[40%] w-[51%] top-[35%]"
            src="../images/x.png"
            alt="x"
          />
        </div>
      </div>
    </section>
  );
};

export default SectionComponent;
